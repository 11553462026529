import type { Commit, Dispatch } from 'vuex';
import {
	NAMESPACE,
	ActionTypes,
	FBXExportFile,
	EXPORT_TYPES,
	VIDEO_FORMAT,
	PixRenderExportPayload,
	ExportImageWithTransformation,
	PixRenderExportType,
	MutationTypes,
	PROJECT_TYPES,
	ExportDownloadItem,
	ExportItemsFilter,
	ExportModalTab,
	IExportVideoPayload,
	ExportAIStyledImageWithTransformation,
	PixRenderFormat,
	EffectExportOptions,
	BackgroundExportOptions,
	EFFECT_IMAGE_TYPE,
} from '@pixcap/ui-core/models/store/projectexports.interface';
import { appUtilities } from '@/modules/appUtilities';

export const actionsWrapper = {
	async exportVideo(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			projectTitle: string;
			width: number;
			height: number;
			format: VIDEO_FORMAT;
			startFrame: number;
			endFrame: number;
			fps: number;
			overridingWatermarkFlag?: boolean;
			isDownload?: boolean;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.EXPORT_VIDEO}`, payload, { root: true });
	},
	async exportGIF(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			projectTitle: string;
			width: number;
			height: number;
			startFrame: number;
			endFrame: number;
			fps: number;
			frameDropIncrement: number;
			transparentBackground?: boolean;
			overridingWatermarkFlag?: boolean;
			isDownload?: boolean;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.EXPORT_GIF}`, payload, { root: true });
	},
	async exportVideoOrGIF(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			projectTitle: string;
			exportFrameIndices: number[]; // only export frames specified in array
			width: number;
			height: number;
			fps: number;
			repeat?: number;
			format: string;
			loadingMessage: string;
			exportType: 'video' | 'gif';
			startFrame: number;
			endFrame: number;
			transparentBackground?: boolean;
			overridingWatermarkFlag?: boolean;
			isDownload: boolean;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.EXPORT_VIDEO_OR_GIF}`, payload, { root: true });
	},
	async exportImage(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			projectId: string;
			filename: string;
			width: number;
			height: number;
			isPng: boolean;
			transparentBackground: boolean;
			scaleFactor?: number;
			background?: BackgroundExportOptions;
			backgroundEffect?: EffectExportOptions;
			isDownload?: boolean;
			isPublish?: boolean;
			addWatermark?: boolean;
			sceneItemIds?: string[];
			shouldCountExport?: boolean;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.EXPORT_IMAGE}`, payload, { root: true });
	},
	async fetchFBXFiles({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_FBX_FILES}`, undefined, { root: true });
	},
	async cancelExportFBX({ dispatch }: { dispatch: Dispatch }, exportId: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.CANCEL_EXPORT_FBX}`, exportId, { root: true });
	},
	async downloadFBXFile({ dispatch }: { dispatch: Dispatch }, fbxFile: FBXExportFile) {
		return dispatch(`${NAMESPACE}/${ActionTypes.DOWNLOAD_FBX_FILE}`, fbxFile, { root: true });
	},
	async subscribeToExportFBXReady({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SUBSCRIBE_TO_EXPORT_FBX_READY}`, undefined, { root: true });
	},
	async exportFBX(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			projectTitle: string;
			exportType?: EXPORT_TYPES;
			doNotExportMuteLayers: boolean;
			projectId: string;
			startFrame: number;
			endFrame: number;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.EXPORT_FBX}`, payload, { root: true });
	},
	async exportProject(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			projectId: string;
			filePrefix: string;
			asGLB: boolean;
			exportType?: EXPORT_TYPES;
			doNotExportMuteLayers: boolean;
			startFrame: number;
			endFrame: number;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.EXPORT_PROJECT}`, payload, { root: true });
	},
	async checkCanExport3dFile({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.CHECK_CAN_EXPORT_3D_FILE}`, undefined, { root: true });
	},
	async subscribeToServerRenderProgress({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SUBSCRIBE_TO_SERVER_RENDER_PROGRESS}`, undefined, { root: true });
	},
	async serverRender(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			fileName: string;
			format: 'PNG' | 'JPEG' | 'FFMPEG' | 'MP4' | 'GIF' | 'WEBM';
			exportType: PixRenderExportType;
			shadowSoftness?: string;
			projectId?: string;
			width?: number;
			height?: number;
			samples?: number;
			scaleFactor?: number;
			skipEvents?: boolean;
			fps?: number;
			repeat?: number;
			currentFrame?: number;
			frameRange?: [number, number];
			downloadImmediately?: boolean;
			background?: BackgroundExportOptions;
			backgroundEffect?: EffectExportOptions;
			isDownload?: boolean;
			isPreviewFileRender?: boolean;
			addWatermark?: boolean;
			sceneItemIds?: string[];
			readyCallback?: (presignedUrl?: string) => void;
			serverExportIdChangeCallback?: (payload: { renderId: string; isChangeCallback?: boolean; currentSelectedExport?: string }) => void;
			serverConfigReadyCallback?: () => void;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SERVER_RENDER}`, payload, { root: true });
	},
	async fetchExportDownloads(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			refresh?: boolean;
			page: number;
			pageSize?: number;
			refreshOnSuccess?: boolean;
			animation?: string;
			search?: string;
			type?: string;
			status?: string;
			sortBy?: string;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_EXPORT_DOWNLOADS}`, payload, { root: true });
	},
	async downloadExportDownload(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			exportType: string;
			format: string;
			fileName: string;
			outputFileId: string;
			renderId: string;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.DOWNLOAD_EXPORT_DOWNLOAD}`, payload, { root: true });
	},
	async downloadAiGeneratedFile({ dispatch }: { dispatch: Dispatch }, id: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.DOWNLOAD_AI_GENERATED_FILE}`, id, { root: true });
	},
	async downloadServerRenderFileByRenderId(
		{ dispatch }: { dispatch: Dispatch },
		payload: { renderId: string; downloadIfNotDownloadedAlready?: boolean }
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.DOWNLOAD_SERVER_RENDER_FILE_BY_RENDER_ID}`, payload, { root: true });
	},
	async deleteServerRenderFile({ dispatch }: { dispatch: Dispatch }, renderId: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.DELETE_SERVER_RENDER_FILE}`, renderId, { root: true });
	},
	async retryServerRenderExport(
		{ dispatch }: { dispatch: Dispatch },
		payload: { renderId: string; format: PixRenderFormat; exportType: PixRenderExportType }
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.RETRY_SERVER_RENDER_EXPORT}`, payload, { root: true });
	},
	async cancelServerRenderExport({ dispatch }: { dispatch: Dispatch }, payload: { renderId: string; shouldRemove?: boolean }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.CANCEL_SERVER_RENDER_EXPORT}`, payload, { root: true });
	},
	async exportImageWithTransformation({ dispatch }: { dispatch: Dispatch }, payload: ExportImageWithTransformation) {
		return dispatch(`${NAMESPACE}/${ActionTypes.EXPORT_IMAGE_WITH_TRANSFORMATION}`, payload, { root: true });
	},
	async exportAIStyledImageWithTransformation({ dispatch }: { dispatch: Dispatch }, payload: ExportAIStyledImageWithTransformation) {
		return dispatch(`${NAMESPACE}/${ActionTypes.EXPORT_AI_STYLED_IMAGE_WITH_TRANSFORMATION}`, payload, { root: true });
	},
	async populateImageToMyDownloads(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			width: number;
			height: number;
			isRemoveBackground: boolean;
			isUpscale: boolean;
			jobId?: string;
			imageStyleId: string;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.POPULATE_IMAGE_TO_MY_DOWNLOADS}`, payload, { root: true });
	},
	async publishSceneToCommunity({ dispatch }: { dispatch: Dispatch }, renderId: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.PUBLISH_SCENE_TO_COMMUNITY}`, renderId, { root: true });
	},
	async publishAISceneToCommunity({ dispatch }: { dispatch: Dispatch }, jobId: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.PUBLISH_AI_SCENE_TO_COMMUNITY}`, jobId, { root: true });
	},
	async populateDefaultDownloadToMyDownloads(
		{ dispatch }: { dispatch: Dispatch },
		payload: { width: number; height: number; format: string; projectId: string; fileName: string; key: string }
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.POPULATE_DEFAULT_DOWNLOAD_TO_MY_DOWNLOADS}`, payload, { root: true });
	},
	async countProjectExport({ dispatch }: { dispatch: Dispatch }, projectId: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.COUNT_PROJECT_EXPORT}`, projectId, { root: true });
	},
	async pollOngoingServerExportStatus({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.POLL_ONGOING_SERVER_EXPORT_STATUS}`, undefined, { root: true });
	},
	async removeImageBackground({ dispatch }: { dispatch: Dispatch }, base64Image: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.REMOVE_IMAGE_BACKGROUND}`, base64Image, { root: true });
	},
	async exportSelectedImage({ dispatch }: { dispatch: Dispatch }, base64Image: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.EXPORT_SELECTED_IMAGE}`, base64Image, { root: true });
	},
};

export const mutationsWrapper = {
	setShowExportFBXModal({ commit }: { commit: Commit }, showExportFBXModal: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_EXPORT_MODAL}`, showExportFBXModal, { root: true });
	},
	setShowExportImageModal({ commit }: { commit: Commit }, showExportImageModal: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_EXPORT_IMAGE_MODAL}`, showExportImageModal, { root: true });
	},
	setExportVideoModal({ commit }: { commit: Commit }, payload: { title: string; startFrame: number; endFrame: number; fps: number } | undefined) {
		commit(`${NAMESPACE}/${MutationTypes.SET_EXPORT_VIDEO_MODAL}`, payload, { root: true });
	},
	setExportGifModal({ commit }: { commit: Commit }, payload: { title: string; startFrame: number; endFrame: number; fps: number } | undefined) {
		commit(`${NAMESPACE}/${MutationTypes.SET_EXPORT_GIF_MODAL}`, payload, { root: true });
	},
	setShowExportProjectModal({ commit }: { commit: Commit }, showExportProjectModal: PROJECT_TYPES) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_EXPORT_PROJECT_MODAL}`, showExportProjectModal, { root: true });
	},
	setExportedFBXFiles({ commit }: { commit: Commit }, exportedFBXFiles: FBXExportFile | FBXExportFile[]) {
		commit(`${NAMESPACE}/${MutationTypes.SET_EXPORTED_FBX_FILES}`, exportedFBXFiles, { root: true });
	},
	updateExportFbxFile({ commit }: { commit: Commit }, exportFbxFile: FBXExportFile) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_EXPORT_FBX_FILE}`, exportFbxFile, { root: true });
	},
	deleteFbxExportFile({ commit }: { commit: Commit }, exportId: string) {
		commit(`${NAMESPACE}/${MutationTypes.DELETE_FBX_EXPORT_FILE}`, exportId, { root: true });
	},
	setIsExportingVideo({ commit }: { commit: Commit }, isExportingVideo: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_EXPORTING_VIDEO}`, isExportingVideo, { root: true });
	},
	updateExportingVideoStatus({ commit }: { commit: Commit }, payload) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_EXPORTING_VIDEO_STATUS}`, payload, { root: true });
	},

	setExportVideoPayload({ commit }: { commit: Commit }, exportVideoPayload: IExportVideoPayload) {
		commit(`${NAMESPACE}/${MutationTypes.SET_VIDEO_EXPORT_PAYLOAD}`, exportVideoPayload, { root: true });
	},
	setExportModalActiveTab({ commit }: { commit: Commit }, exportModalActiveTab: ExportModalTab) {
		commit(`${NAMESPACE}/${MutationTypes.SET_EXPORT_MODAL_ACTIVE_TAB}`, exportModalActiveTab, { root: true });
	},
	resetServerExportItems({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_SERVER_EXPORT_ITEMS}`, undefined, { root: true });
	},
	setExportItemsFilter({ commit }: { commit: Commit }, exportItemsFilter: ExportItemsFilter) {
		commit(`${NAMESPACE}/${MutationTypes.SET_EXPORT_ITEMS_FILTER}`, exportItemsFilter, { root: true });
	},
	addOrUpdateServerExportItem({ commit }: { commit: Commit }, payload: PixRenderExportPayload) {
		//commit(`${NAMESPACE}/${MutationTypes.ADD_OR_UPDATE_SERVER_EXPORT_ITEM}`, payload, { root: true });
		const updateExportItemPayload = (updatePayload: PixRenderExportPayload) => {
			commit(`${NAMESPACE}/${MutationTypes.ADD_OR_UPDATE_SERVER_EXPORT_ITEM}`, updatePayload, { root: true });
		};
		appUtilities.$services.serverRenderProgressSimulator.addOrUpdateServerExportItem(payload, updateExportItemPayload);
	},
	removeServerExportItem({ commit }: { commit: Commit }, renderId: string) {
		appUtilities.$services.serverRenderProgressSimulator.removeServerExportItem(renderId);
		commit(`${NAMESPACE}/${MutationTypes.REMOVE_SERVER_EXPORT_ITEM}`, renderId, { root: true });
	},
	removeExportDownloadItem({ commit }: { commit: Commit }, renderId: string) {
		commit(`${NAMESPACE}/${MutationTypes.REMOVE_EXPORT_DOWNLOAD_ITEM}`, renderId, { root: true });
	},
	setIsFetchingExportDownloads({ commit }: { commit: Commit }, setIsFetchingExportDownloads: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_EXPORT_DOWNLOADS}`, setIsFetchingExportDownloads, { root: true });
	},
	setExportDownloads(
		{ commit }: { commit: Commit },
		items: ExportDownloadItem[],
		totalItems: number,
		totalPages: number,
		currentPage: number,
		refresh: boolean
	) {
		commit(`${NAMESPACE}/${MutationTypes.SET_EXPORT_DOWNLOADS}`, { items, totalItems, totalPages, currentPage, refresh }, { root: true });
	},
	addOrUpdateExportDownloadItem({ commit }: { commit: Commit }, payload: PixRenderExportPayload) {
		commit(`${NAMESPACE}/${MutationTypes.ADD_OR_UPDATE_EXPORT_DOWNLOAD_ITEM}`, payload, { root: true });
	},
	setSelectedServerExportItem({ commit }: { commit: Commit }, selectedServerExportItem: string) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SELECTED_SERVER_EXPORT_ITEM}`, selectedServerExportItem, { root: true });
	},
	setServerExportItemToCancel({ commit }: { commit: Commit }, serverExportItemToCancel: string) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SERVER_EXPORT_ITEM_TO_CANCEL}`, serverExportItemToCancel, { root: true });
	},
	resetExportDownloads({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_EXPORT_DOWNLOADS}`, undefined, { root: true });
	},
};
