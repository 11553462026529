import type { ActionContext } from 'vuex';
import axios from 'axios';
import { ActionTypes, AuthInfo, IAuthState } from '@pixcap/ui-core/models/store/auth.interface';
import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user/wrapper';
import { actionsWrapper, mutationsWrapper } from '@pixcap/ui-core/store/auth/wrapper';
import { appUtilities } from '@pixcap/ui-core/modules/appUtilities';
import { httpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { REFERRAL_CODE_KEY, SHOULD_SHOW_PROMOTION_BANNER, STORAGE_INVITE_CODE_KEY } from '@pixcap/ui-core/constants/auth.constants';
import { REF_ORIGIN_SOURCE, UTM_ORIGIN_SOURCE } from '@pixcap/ui-core/constants/shared.constants';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
import { $notify } from '@pixcap/ui-core/helpers/notification';
import logger from '@pixcap/ui-core/helpers/logger';
import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';

const { AUTH_PATH, PAYMENTS_PATH, ASSETMANAGER_PATH } = API_PATHS;

export default {
	async [ActionTypes.REFRESH](context: ActionContext<any, any>) {
		try {
			mutationsWrapper.setIsRefreshingToken(context, true);
			const response = await httpClient.get(`${AUTH_PATH}/refresh`);
			if (response.data) {
				const payload = {
					idToken: response.data.idToken,
					userId: response.data.userId,
				};
				mutationsWrapper.refresh(context, payload);
				UserMutations.setUserId(context, payload.userId);
				appUtilities.$mixpanel.identify(payload.userId);
				mutationsWrapper.store(context, payload);
				return true;
			}
		} catch (error) {
			throw error;
		} finally {
			mutationsWrapper.setIsRefreshingToken(context, false);
		}
		return false;
	},
	async [ActionTypes.LOGOUT](context: ActionContext<any, any>, payload?: { sessionChange: boolean }) {
		try {
			mutationsWrapper.logout(context);
			UserMutations.setUserId(context, null);
			await axios.post(`${AUTH_PATH}/signOut`);
			if (payload) {
				if (payload.sessionChange)
					$notify({ type: NotificationType.WARN, title: 'Your session has been terminated due to a new login from another location.' });
				else $notify({ type: NotificationType.SUCCESS, title: 'You have logged out successfully' });
			}
			UserMutations.updatePersonalData(context, undefined);
			UserMutations.setSubscriptionInfo(context, {
				subscription: null,
				price: null,
				paymentStatus: null,
				currentPeriodEnd: '',
				isFreeTrialInitiated: false,
				pricingCountryCode: null,
			});
			UserMutations.setReferralsInfo(context, {
				referralCode: null,
				rewards: [],
			});
			appUtilities.$services.localStorageManager.remove(SHOULD_SHOW_PROMOTION_BANNER);
			setTimeout(() => {
				if (appUtilities.$services.serviceWorkerManager) appUtilities.$services.serviceWorkerManager.postLogoutMessage();
			}, 500);
		} finally {
			delete axios.defaults.headers.common['Authorization'];
			appUtilities.$mixpanel.reset();
			if (appUtilities.$crisp) appUtilities.$crisp('logout');
		}
	},
	async [ActionTypes.RESEND_EMAIL_VERIFICATION](context: ActionContext<any, any>, userNameOrEmail: string) {
		try {
			await httpClient.get(`${AUTH_PATH}/emailVerification/resend/${userNameOrEmail}`);
		} catch (err) {
			logger.error('Failed to resend email verification', { err });
		}
	},
	async [ActionTypes.VERIFY_EMAIL](context: ActionContext<any, any>, payload: { email: string; verificationKey: string }) {
		const { email, verificationKey } = payload;
		try {
			mutationsWrapper.updateSubmitStatus(context, true);
			const response = await httpClient.get(`${AUTH_PATH}/emailVerification/${email}`, {
				params: {
					verificationKey: verificationKey,
				},
			});
			const { idToken, userId } = response.data;
			appUtilities.$mixpanel.identify(userId);
			appUtilities.$mixpanel.track('Login', { 'Login Method': 'Pixcap' });
			if (appUtilities.$crisp) appUtilities.$crisp('login', userId, email);
			return { idToken, userId };
		} catch (err) {
			logger.error(err);
			return null;
		} finally {
			mutationsWrapper.updateSubmitStatus(context, false);
		}
	},
	async [ActionTypes.CHANGE_PASSWORD](context: ActionContext<any, any>, payload: { oldPassword: string; newPassword: string }) {
		const { oldPassword, newPassword } = payload;
		await httpClient.post(`${AUTH_PATH}/changePassword`, {
			oldPassword,
			newPassword,
		});
	},
	async [ActionTypes.STORE](context: ActionContext<any, any>, tokenPayload: AuthInfo) {
		mutationsWrapper.store(context, tokenPayload);
	},
	async [ActionTypes.SIGN_IN](context: ActionContext<any, any>, payload: { email: string; password: string }) {
		mutationsWrapper.updateSubmitStatus(context, true);
		const { email, password } = payload;
		try {
			const response = await httpClient.post(`${AUTH_PATH}/signin`, {
				email,
				password,
			});
			const { idToken, userId } = response.data;
			appUtilities.$mixpanel.identify(userId);
			appUtilities.$mixpanel.track('Login', { 'Login Method': 'Pixcap' });
			mutationsWrapper.store(context, response.data);
			if (appUtilities.$crisp) appUtilities.$crisp('login', userId, email);
			return { idToken, userId };
		} finally {
			mutationsWrapper.updateSubmitStatus(context, false);
		}
	},
	async [ActionTypes.SIGN_UP](context: ActionContext<any, any>, payload: { email: string; password: string }) {
		mutationsWrapper.updateSubmitStatus(context, true);
		try {
			const email = payload.email.toLowerCase();
			const password = payload.password;

			const refOriginSource = localStorage.getItem(REF_ORIGIN_SOURCE);
			const utmOriginSource = localStorage.getItem(UTM_ORIGIN_SOURCE);
			if (refOriginSource) localStorage.removeItem(REF_ORIGIN_SOURCE);

			const inviteCode = appUtilities.$services.localStorageManager.read(STORAGE_INVITE_CODE_KEY);
			const referralCode = appUtilities.$services.localStorageManager.read(REFERRAL_CODE_KEY);
			const requestBody: any = {
				password,
				email,
			};
			if (inviteCode) requestBody.inviteCode = inviteCode;
			if (referralCode) requestBody.referralCode = referralCode;
			const { anonymousSession }: IAuthState = context.state;
			if (anonymousSession && anonymousSession.session) requestBody.anonymousUser = anonymousSession.session;
			const response = await httpClient.post(`${AUTH_PATH}/signUp`, requestBody);
			const { userId } = response.data;
			appUtilities.$mixpanel.alias(userId);
			appUtilities.$mixpanel.identify(userId);
			appUtilities.$mixpanel.people.set({ $email: email });
			appUtilities.$mixpanel.track('Sign Up', {
				Method: referralCode ? 'Referral Program' : 'Regular',
				'Referral Code': referralCode ? referralCode : null,
				'Ref Origin Source': refOriginSource,
				'Utm Origin Source': utmOriginSource,
			});
			try {
				appUtilities.$GTM_dataLayer('new_sign_up', email);
			} catch (err) {
				logger.error(err);
			}
		} finally {
			mutationsWrapper.updateSubmitStatus(context, false);
		}
	},
	async [ActionTypes.FORGET_PASSWORD](context: ActionContext<any, any>, payload: { email: string; passUrl?: boolean }) {
		const { email, passUrl = true } = payload;
		mutationsWrapper.updateSubmitStatus(context, true);
		try {
			await httpClient.post(`${AUTH_PATH}/forgotPassword`, { email, passUrl });
		} finally {
			mutationsWrapper.updateSubmitStatus(context, false);
		}
	},
	async [ActionTypes.RESET_PASSWORD](context: ActionContext<any, any>, payload: { email: string; confirmationCode: string; password: string }) {
		const { email, confirmationCode, password } = payload;
		mutationsWrapper.updateSubmitStatus(context, true);
		try {
			await httpClient.post(`${AUTH_PATH}/resetPassword`, {
				email,
				password,
				confirmationCode,
			});
		} finally {
			mutationsWrapper.updateSubmitStatus(context, false);
		}
	},
	async [ActionTypes.SEND_AUTH_CODE](
		context: ActionContext<any, any>,
		payload: { code: string; provider: string; codeType?: string; anonymousUser?: string }
	) {
		const { code, provider, codeType, anonymousUser } = payload;
		const inviteCode = appUtilities.$services.localStorageManager.read(STORAGE_INVITE_CODE_KEY);
		const referralCode = appUtilities.$services.localStorageManager.read(REFERRAL_CODE_KEY);
		const requestBody: any = {
			anonymousUser,
			code,
			...(referralCode && { referralCode }),
			...(inviteCode && { inviteCode }),
			...(provider && { provider }),
			...(codeType && { codeType }),
		};

		const response = await httpClient.post(`${AUTH_PATH}/authorize`, requestBody);
		if (response.data) {
			const { idToken, userId, email, isSocialAuthSignUp } = response.data;
			actionsWrapper.store(context, { idToken, userId });
			return { isSocialAuthSignUp, userId, email, idToken };
		} else {
			return null;
		}
	},
	async [ActionTypes.VALIDATE_REFERRAL_CODE](context: ActionContext<any, any>) {
		const referralCode = appUtilities.$services.localStorageManager.read(REFERRAL_CODE_KEY);
		const response = await httpClient.get(`${PAYMENTS_PATH}/referrals/validate?code=${referralCode}`);
		if (response.data) return response.data;
		return null;
	},

	async [ActionTypes.VALIDATE_REFERRAL_EMAIL](context: ActionContext<any, any>, payload: { email: string }) {
		try {
			const { email } = payload;
			const response = await httpClient.put(`${PAYMENTS_PATH}/referrals/invite`, {
				email: email,
			});
			if (response.data) return response.data;
		} catch (e) {
			return e.data;
		}
	},

	async [ActionTypes.FETCH_INVITED_REFEREE](context: ActionContext<any, any>) {
		try {
			const response = await httpClient.get(`${PAYMENTS_PATH}/referrals/accepted`);
			if (response) return response.data;
		} catch (e) {
			logger.error('Failed to fetch referee list', { e });
		}
	},

	async [ActionTypes.FETCH_PROMOTIONS](context: ActionContext<any, any>) {
		try {
			const response = await httpClient.get(`${PAYMENTS_PATH}/discounts`);
			if (response) return response.data;
		} catch (e) {
			logger.error('Failed to fetch promotion list', { e });
		}
	},
	async [ActionTypes.SYNC_ANONYMOUS_SESSION_WITH_USER](context: ActionContext<any, any>) {
		try {
			const state: IAuthState = context.state;
			if (state.anonymousSession?.session == null) return;
			await httpClient.put(`${ASSETMANAGER_PATH}/project/migrate`, { userId: state.anonymousSession.session });
			mutationsWrapper.setAnonymousSession(context, undefined);
		} catch (e) {
			logger.error('Encounter error while syncing anonymous session with user', { e });
		}
	},
	async [ActionTypes.GRANT_FIGMA_ACCESS](context: ActionContext<any, any>, payload: { clientId: string; userId: string }) {
		try {
			const { clientId, userId } = payload;
			const response = await httpClient.post(`${AUTH_PATH}/grantAccess`, { anonymousUser: userId, clientId });
			return response.data;
		} catch (e) {
			logger.error('Encounter error while grating access to figma', { e });
		}
	},
	async [ActionTypes.CHECK_EMAIL_UNIQUENESS](context: ActionContext<any, any>, email: string) {
		mutationsWrapper.updateSubmitStatus(context, true);
		try {
			await httpClient.get(`${AUTH_PATH}/isEmailUnique/${email}`);
			return { isUnique: true };
		} catch (e) {
			const errorCode = e?.data?.errorCode;
			logger.error('Encounter error while checking email uniqueness', { e });
			switch (errorCode) {
				case 'E01_01_004':
					return { isUnique: false, isPasswordAuth: true };
					break;
				case 'E01_01_007':
					return { isUnique: false, isSocialAuth: true };
					break;
				default:
					return { isUnique: false };
					break;
			}
			return true;
		} finally {
			mutationsWrapper.updateSubmitStatus(context, false);
		}
	},
};
